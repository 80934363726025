import Plot from "react-plotly.js";
import {
  formatPlotData,
  generateLineStyle,
  generateMarkerStyle,
  chooseYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  choosePlotTraceName,
  chooseFill,
  chooseFillColor,
  formatDateForTitle,
  findHindcastStart,
  findPostShortTermEnd,
  setYRange,
} from "./PlotHelpers";
import { grabSelectedBasinTs } from "../../Utils/helpers";
import "./MainPlot.css";
import { createShapesMain } from "./Shapes";
import { createAnnotations } from "./Annotations";

export const MainPlot = ({ selectedBasin, allTs, thresholds }) => {
  const timeseries = grabSelectedBasinTs(allTs, selectedBasin);

  const processedData = formatPlotData(timeseries);

  const notRegularTS = (name) => {
    if (
      name === "QPE_to_MAPCumulativePastPrecip" ||
      name === "PreprocessHRRRCumulativeShortPrecip" ||
      name === "PreprocessGFSCumulativeMedPrecip" ||
      name === "ThresholdsBooleanThreshold"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      let tsObject;
      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseYAxis(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseYAxis(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),

          showlegend:
            ts.name === "ThresholdsStreamflowThreshold" ||
            ts.name === "Preprocess_NWMMedRangeMax"
              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
        };
      }

      return tsObject;
    })
    .sort((a, b) => (a.name > b.name && 1) || -1);

  const shapesArray = createShapesMain(
    plotableTS,
    timeseries,
    thresholds,
    selectedBasin
  );

  const annotationsArray = createAnnotations(
    timeseries,
    thresholds,
    selectedBasin
  );

  return (
    <Plot
      data={generatePlotDataFormat}
      layout={{
        autosize: true,
        title: {
          text:
            selectedBasin.split(/(?=[A-Z])/).join(" ") +
            ": " +
            formatDateForTitle(
              plotableTS.find((ts) => ts.name === "Preprocess_NWMShortRange")
                .forecastDateTime.date +
                "T" +
                plotableTS.find((ts) => ts.name === "Preprocess_NWMShortRange")
                  .forecastDateTime.time
            ),
          font: {
            color: "black",
            size: 24,
            },
          },
        legend: { y: -0.3, orientation: "h" },
        showlegend: true,
        hovermode: "closest",
        
        yaxis2: {
          autoscale: true,
          title: {
            text: "Streamflow<br> (cfs)",
            font: {
              size: 11,
            },
          },
          gridcolor: "lightgrey",
          hoverformat: ".0f",
          mirror: "all",
          showgrid: true,
          showline: true,
          tickformat: ",",
          zeroline: true,
          domain: [0, 0.4],
          rangemode: "tozero",
          tickfont: {
            size: 11,
          },
        },
        yaxis3: {
          autoscale: true,
          title: {
            text: "Precip<br>(in)",
            font: {
              size: 11,
            },
          },
          gridcolor: "lightgrey",
          hoverformat: ".2f",
          mirror: "all",
          showgrid: true,
          showline: true,
          tickformat: ".2f",
          zeroline: true,
          domain: [0.47, 0.67],
          rangemode: "tozero",
          range: setYRange(generatePlotDataFormat, "y2"),
          tickfont: {
            size: 11,
          },
        },
        yaxis4: {
          autoscale: true,
          title: {
            text: "Precip<br>(in)",
            font: {
              size: 11,
            },
          },
          gridcolor: "lightgrey",
          hoverformat: ".2f",
          mirror: "all",
          showgrid: true,
          showline: true,
          tickformat: ".2f",
          zeroline: true,
          domain: [0.75, 0.95],
          rangemode: "tozero",
          range: setYRange(generatePlotDataFormat, "y3"),
          tickfont: {
            size: 11,
          },
        },
        xaxis: {
          title: {
            text: "Datetime (EST)",
            font: {
              size: 11,
            },
          },
          gridcolor: "lightgrey",
          mirror: "all",
          showgrid: true,
          showline: true,
          zeroline: true,
          //TO DO set to hindcast start, max is forecast + 10 ish days
          range: [
            findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
            findPostShortTermEnd(timeseries, "PreprocessHRRR"),
          ],
          tickfont: {
            size: 11,
          },
        },
        margin: { r: 20 },
        annotations: annotationsArray,
        shapes: shapesArray
      
      }}
      useResizeHandler={true}
      className="main-plot"
    />
  );
};
