import { Grid, Paper, styled } from "@mui/material";
import { ThumbnailPlotCustom } from "./ThumbnailPlotCustom.jsx";


const ThumbnailItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "40vh",
}));

export const ThumbnailContainerCustom = ({ allTs, watershedId, basinClick, goBackDays }) => {
  //filter for selected watershed
  //const allTsFilter = allTs.filter((ts) => ts.name.includes(watershedId))
  return allTs.map((basin) => {
    return (
      <Grid item xs={4} key={basin.name}>
        <ThumbnailItem>
          <ThumbnailPlotCustom basin={basin} basinClick={basinClick} goBackDays={goBackDays} />
        </ThumbnailItem>
      </Grid>
    );
  });
};
