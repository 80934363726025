import {
  subtractDays
} from "./PlotCustomHelpers";


export const createShapesMainCustom = (
  selectedBasin
) => {
  const shapes = [
    {
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: subtractDays(Date.now(), 10),
      y0: 0,
      x1: Date.now(),
      y1: 0.2,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: subtractDays(Date.now(), 10),
      y0: 0.22,
      x1: Date.now(),
      y1: 0.42,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },

    {
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: subtractDays(Date.now(), 10),
      y0: 0.44,
      x1: Date.now(),
      y1: 0.64,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: subtractDays(Date.now(), 10),
      y0: 0.66,
      x1: Date.now(),
      y1: 0.86,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
  ];
return shapes;

};

export const createShapesThumbnailCustom = (plotableTS, timeseries) => {
  const shapes = [
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      // x0: findHindcastStart(plotableTS, "QPE_to_MAP_custommerged"),
      // y0: 0,
      // x1: findHindcastEnd(plotableTS, "Merge_MAP_GFS_"),
      x0: subtractDays(Date.now(), 10),
      y0: 0,
      x1: Date.now(),
      y1: 0.4,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      // x0: findHindcastStart(plotableTS, "QPE_to_MAP_custommerged"),
      // y0: 0.75,
      // x1: findHindcastEnd(plotableTS, "Merge_MAP_GFS_"),
      x0: subtractDays(Date.now(), 10),
      y0: 0.75,
      x1: Date.now(),
      y1: 0.95,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "before_medium_range_forcast",
      layer: "above",
      type: "rect",
      xref: "x",
      yref: "paper",
      // x0: findHindcastStart(plotableTS, "QPE_to_MAP_custommerged"),
      // y0: 0.47,
      // x1: findPreMediumForecastEnd(plotableTS, "PreprocessGFS"),
      x0: subtractDays(Date.now(), 10),
      y0: 0.47,
      x1: Date.now(),
      y1: 0.67,
      fillcolor: "rgb(96, 96, 96)",
      opacity: 1,
      line: {
        width: 0,
        color: "black",
      },
    },
    // {
    //   wfpType: "beyond_short_term_forcast",
    //   layer: "above",
    //   type: "rect",
    //   xref: "x",
    //   yref: "paper",
    //   // x0: findPostShortTermStart(timeseries, "PreprocessHRRR"),
    //   // y0: 0.75,
    //   // x1: findPostShortTermEnd(timeseries, "PreprocessHRRR"),
    //   x0: subtractDays(Date.now(), 10),
    //   y0: 0.75,
    //   x1: Date.now(),
    //   y1: 0.95,
    //   fillcolor: "rgb(96, 96, 96)",
    //   opacity: 1,
    //   line: {
    //     width: 0,
    //     color: "black",
    //   },
    // },
  ];

  if (shapes) {
    return shapes;
  } else {
    return [];
  }
};
