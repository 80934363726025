import {
  HRRR_color,
  GFS_color,
  NBM_color,
  ECMWF_color,
  BestAvailable_color,
  subtractDays
} from "./PlotCustomHelpers";

export const createArrayHRRR = (ts) => {
  // extract the total precip amount in each 24 hours for the 10 day forecast
  // last value in each day at 23:00 should be the value of the day's cumulative ppt
  // get that value and the date in an object

  // group timesteps by day midnight - 11 pm
  // this returns an object with key = day and value = array of precip values (but as strings)
  const groupByDay = ts.events.reduce(function (obj, timestep) {
    obj[timestep.date] = obj[timestep.date] || [];
    obj[timestep.date].push(timestep.value);
    return obj;
  }, {});

  const arrayOfMedCumulPrecip = Object.keys(groupByDay).map(function (key) {
    return {
      day: key,
      cumulPrecip: groupByDay[key]
        .map((value) => parseFloat(value))
        .reduce((prev, next) => prev + next),
    };
  });
  // now loop through this array to create the annotation for each one
  const arrayToAddToAnnotations = [{
    wfpType: "accumulation_anno",
    aggregation_name: "MRPB",
    visible: true,
    xref: 'paper',
    yref: "paper",
    y: 1.0,
    xanchor: "left",
    x: subtractDays(Date.now(),1),
    yanchor: "middle",
    text: "    HRRR",
    showarrow: false,
    font: {
      color: HRRR_color,
      size: 9,
    },
  }];
  arrayOfMedCumulPrecip.forEach((daySumPpt) => {
    arrayToAddToAnnotations.push({
      wfpType: "accumulation_anno",
      aggregation_name: "MRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 1.0,
      xanchor: "center",
      x: daySumPpt.day + " 12:00:00",
      yanchor: "middle",
      text: daySumPpt.cumulPrecip.toFixed(2),
      showarrow: false,
      font: {
        color: HRRR_color,
        size: 10,
      },
    });
  });
  return arrayToAddToAnnotations;
};

export const createArrayGFS = (ts) => {
  const groupByDay = ts.events.reduce(function (obj, timestep) {
    obj[timestep.date] = obj[timestep.date] || [];
    obj[timestep.date].push(timestep.value);
    return obj;
  }, {});
  const arrayOfMedCumulPrecip = Object.keys(groupByDay).map(function (key) {
    return {
      day: key,
      cumulPrecip: groupByDay[key]
        .map((value) => parseFloat(value))
        .reduce((prev, next) => prev + next),
    };
  });
  // now loop through this array to create the annotation for each one
  const arrayToAddToAnnotations = [{
    wfpType: "accumulation_anno",
    aggregation_name: "MRPB",
    visible: true,
    xref: 'paper',
    yref: "paper",
    y: 0.97,
    xanchor: "left",
    x: subtractDays(Date.now(),1),
    yanchor: "middle",
    text: "    GFS",
    showarrow: false,
    font: {
      color: GFS_color,
      size: 9,
    },
  }];
  arrayOfMedCumulPrecip.forEach((daySumPpt) => {
    arrayToAddToAnnotations.push({
      wfpType: "accumulation_anno",
      aggregation_name: "MRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 0.97,
      xanchor: "center",
      x: daySumPpt.day + " 12:00:00",
      yanchor: "middle",
      text: daySumPpt.cumulPrecip.toFixed(2),
      showarrow: false,
      font: {
        color: GFS_color,
        size: 10,
      },
    });
  });
  return arrayToAddToAnnotations;
};

export const createArrayECMWF = (ts) => {
  // extract the total precip amount in each 24 hours for the 10 day forecast
  // last value in each day at 23:00 should be the value of the day's cumulative ppt
  // get that value and the date in an object

  // group timesteps by day midnight - 11 pm
  // this returns an object with key = day and value = array of precip values (but as strings)
  //console.log("ts ", ts)
  const groupByDay = ts.events.reduce(function (obj, timestep) {
    obj[timestep.date] = obj[timestep.date] || [];
    obj[timestep.date].push(timestep.value);
    //console.log("obj:", obj)
    return obj;

  }, {});

  const arrayOfMedCumulPrecip = Object.keys(groupByDay).map(function (key) {
    return {
      day: key,
      cumulPrecip: groupByDay[key]
        .map((value) => parseFloat(value))
        .reduce((prev, next) => prev + next),
    };
  });
  // now loop through this array to create the annotation for each one
  const arrayToAddToAnnotations = [{
    wfpType: "accumulation_anno",
    aggregation_name: "MRPB",
    visible: true,
    xref: 'paper',
    yref: "paper",
    y: 0.94,
    xanchor: "left",
    x: subtractDays(Date.now(),1),
    yanchor: "middle",
    text: "    ECMWF",
    showarrow: false,
    font: {
      color: ECMWF_color,
      size: 9,
    },
  }];
  arrayOfMedCumulPrecip.forEach((daySumPpt) => {
    arrayToAddToAnnotations.push({
      wfpType: "accumulation_anno",
      aggregation_name: "MRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 0.94,
      xanchor: "center",
      x: daySumPpt.day + " 12:00:00",
      yanchor: "middle",
      text: daySumPpt.cumulPrecip.toFixed(2),
      showarrow: false,
      font: {
        color: ECMWF_color,
        size: 10,
      },
    });
  });

  return arrayToAddToAnnotations;
};

export const createArrayNBM = (ts) => {
  // extract the total precip amount in each 24 hours for the 10 day forecast
  // last value in each day at 23:00 should be the value of the day's cumulative ppt
  // get that value and the date in an object

  // group timesteps by day midnight - 11 pm
  // this returns an object with key = day and value = array of precip values (but as strings)
  const groupByDay = ts.events.reduce(function (obj, timestep) {
    obj[timestep.date] = obj[timestep.date] || [];
    obj[timestep.date].push(timestep.value);
    return obj;
  }, {});

  const arrayOfMedCumulPrecip = Object.keys(groupByDay).map(function (key) {
    return {
      day: key,
      cumulPrecip: groupByDay[key]
        .map((value) => parseFloat(value))
        .reduce((prev, next) => prev + next),
    };
  });
  // now loop through this array to create the annotation for each one
  const arrayToAddToAnnotations = [{
    wfpType: "accumulation_anno",
    aggregation_name: "MRPB",
    visible: true,
    xref: 'paper',
    yref: "paper",
    y: 0.91,
    xanchor: "left",
    x: subtractDays(Date.now(),1),
    yanchor: "middle",
    text: "    NBM",
    showarrow: false,
    font: {
      color: NBM_color,
      size: 9,
    },
  }];
  arrayOfMedCumulPrecip.forEach((daySumPpt) => {
    arrayToAddToAnnotations.push({
      wfpType: "accumulation_anno",
      aggregation_name: "MRPB",
      visible: true,
      xref: "x",
      yref: "paper",
      y: 0.91,
      xanchor: "center",
      x: daySumPpt.day + " 12:00:00",
      yanchor: "middle",
      text: daySumPpt.cumulPrecip.toFixed(2),
      showarrow: false,
      font: {
        color: NBM_color,
        size: 10,
      },
    });
  });

  return arrayToAddToAnnotations;
};


export const createAnnotationsCustom = (timeseries, thresholds, selectedBasin) => {
  // console.log("create annot custom timeseries:", timeseries);
  //these are just the things that say units at top right of plot
  const annotations = [
    // {
    //   wfpType: "accumulation_title",
    //   visible: true,
    //   xref: "paper",
    //   yref: "paper",
    //   y: 0.7,
    //   xanchor: "left",
    //   x: 0.99,
    //   yanchor: "middle",
    //   text: "(in)",
    //   showarrow: false,
    //   font: {
    //     color: "black",
    //     size: 12,
    //   },
    // },

    // {
    //   wfpType: "accumulation_title",
    //   visible: true,
    //   xref: "paper",
    //   yref: "paper",
    //   y: 0.98,
    //   xanchor: "left",
    //   x: 0.93,
    //   yanchor: "middle",
    //   text: "(in)",
    //   showarrow: false,
    //   font: {
    //     color: "rgb(33, 97, 140)",//"black",
    //     size: 10.5,
    //   },
    // },

    // {
    //   wfpType: "threshold_anno",
    //   visible: thresholdVisible(thresholds, selectedBasin),
    //   xref: "paper",
    //   yref: "y",
    //   y: findThresholdValue(timeseries),
    //   xanchor: "left",
    //   x: 0,
    //   yanchor: "top",
    //   text: "Threshold",
    //   showarrow: false,
    //   font: {
    //     color: "black",
    //     size: 11,
    //   },
    // },
  ];

  // const customAnnotBestTs = timeseries.find((ts) => {
  //   // console.log("ts.header?.qualifierId",ts.header?.qualifierId[0]);
  //   return (
  //     ts.header?.moduleInstanceId.startsWith("Merge_MAP_BestAvailable_") && ts.header?.qualifierId[0]!=="cumulative"//use the instantaneous one
  //   );
  // });
  // const customAnnot = createArrayBestAvailable(customAnnotBestTs);


  //Original Payload Queries
  /*
    if (ts.header?.moduleInstanceId) {
      console.log("moduleInstanceId:", ts.header.moduleInstanceId);
    }
  */

//Commenting, Not Configured in FEWS Yet
/*
  const customAnnotTsGFS = timeseries.find((ts) => {
      // Ensure ts.header and ts.header.moduleInstanceId are defined before checking startsWith
      const moduleInstanceId = ts.header?.moduleInstanceId;
      const qualifierId = ts.header?.qualifierId;
    
      if (moduleInstanceId && qualifierId) {
        return(
          ts.header?.moduleInstanceId.startsWith("Merge_MAP_GFS_") && ts.header?.qualifierId[0]!=="cumulative"//use the instantaneous one
        )
      }
  });
  const customAnnotGFS = createArrayGFS(customAnnotTsGFS);

  const customAnnotTsNBM = timeseries.find((ts) => {
      // Ensure ts.header and ts.header.moduleInstanceId are defined before checking startsWith
      const moduleInstanceId = ts.header?.moduleInstanceId;
      const qualifierId = ts.header?.qualifierId;
    
      if (moduleInstanceId && qualifierId) {
        return(
          ts.header?.moduleInstanceId.startsWith("Merge_MAP_NBM_") && ts.header?.qualifierId[0]!=="cumulative"//use the instantaneous one
        )
      }
  });
  const customAnnotNBM = createArrayNBM(customAnnotTsNBM);

  const customAnnotTsHRRR = timeseries.find((ts) => {
      // Ensure ts.header and ts.header.moduleInstanceId are defined before checking startsWith
      const moduleInstanceId = ts.header?.moduleInstanceId;
      const qualifierId = ts.header?.qualifierId;
    
      if (moduleInstanceId && qualifierId) {
        return(
          ts.header?.moduleInstanceId.startsWith("Merge_MAP_HRRR_") && ts.header?.qualifierId[0]!=="cumulative"//use the instantaneous one
        )
      }
  });
  const customAnnotHRRR = createArrayHRRR(customAnnotTsHRRR);
  */

  //Recoded
  const customAnnotTsECMWF = timeseries.find((ts) => {
    // Ensure ts.header and ts.header.moduleInstanceId are defined before checking startsWith
    const moduleInstanceId = ts.header?.moduleInstanceId;
    const qualifierId = ts.header?.qualifierId;
  
    if (moduleInstanceId && qualifierId) {
      return(
        ts.header?.moduleInstanceId.startsWith("Merge_MAP_ECMWF_") && ts.header?.qualifierId[0]!=="cumulative"//use the instantaneous one
      )
      }
  });
  const customAnnotECMWF = createArrayECMWF(customAnnotTsECMWF);


/*
  if ((customAnnotGFS, customAnnotNBM, customAnnotHRRR, customAnnotECMWF)) {
    return annotations.concat(customAnnotGFS, customAnnotNBM, customAnnotHRRR, customAnnotECMWF);
  } else if (annotations) {
    return annotations;
  } else {
    return [];
  }
*/

  if ((customAnnotECMWF)) {
    return annotations.concat(customAnnotECMWF);
  } else if (annotations) {
    return annotations;
  } else {
    return [];
  }

};
