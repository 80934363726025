import Plot from "react-plotly.js";
// import {choosePlotTraceName} from "./PlotEvaluateHelpers";
import {formatPlotData,
  generateLineStyle,
  generateMarkerStyle,
  chooseYAxis,
  chooseType,
  chooseOffset,
  chooseWidth,
  formatNameForTitle,
  chooseFill,
  chooseFillColor,
  formatDateForTitle,
  addDays,
  subtractDays,
  setYRange,
  choosePlotTraceName,
  chooseLegendGroup
} from "./PlotCustomHelpers";
import "./MainPlot.css";
import { MoreInfo } from "../Layouts/MoreInfo";
import { createShapesMainEvaluate } from "./ShapesEvaluate";
import { createAnnotationsCustom } from "./AnnotationsCustom";

export const MainEvaluatePlot = ({ selectedBasin, allTs, thresholds }) => {
  const timeseries = allTs.filter(ts => (ts.header.locationId).startsWith(selectedBasin));
  const processedData = formatPlotData(timeseries);
  // console.log("Ttimeseries",timeseries);

  const notRegularTS = (name) => {
    if (
      name === "ThresholdsBooleanThreshold" ||
      name === "ThresholdsCustomBooleanThreshold"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const plotableTS = processedData.filter((ts) => !notRegularTS(ts.name));

  const generatePlotDataFormat = plotableTS
    .map((ts) => {
      // console.log("ts", ts);
      let tsObject;
      if (chooseType(ts.name) === "bar") {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          type: "bar",
          name: choosePlotTraceName(ts.name),
          showlegend:
            ts.name.includes("Merge_MAP_") 
              ? false
              : true,
          marker: generateMarkerStyle(ts.name),
          width: chooseWidth(ts.name),
          offset: chooseOffset(ts.name),
          hovertemplate: 
            '%{y:.2f} in' +
            '<br>%{x}<br>' ,
        };
      } else {
        tsObject = {
          x: ts.x,
          y: ts.y,
          yaxis: chooseYAxis(ts.name),
          legendgroup: chooseLegendGroup(ts.name),
          mode: "lines",
          name: choosePlotTraceName(ts.name),
          line: generateLineStyle(ts.name),
          hovertemplate: 
            '%{y:.2f}' +
            '<br>%{x}<br>' ,
          showlegend:
            ts.name.includes("Preprocess_HRRR_grids_to_FMAP") ||
            ts.name.includes("Preprocess_GFS_grids_to_FMAP") ||
            ts.name.includes("Preprocess_NBM_grids_to_FMAP") ||
            ts.name.includes("Preprocess_ECMWF_grids_to_FMAP")
              ? false
              : true,
          fill: chooseFill(ts.name),
          fillcolor: chooseFillColor(ts.name),
        };
      }
      console.log("tsObject", tsObject);
      return tsObject;
      
    })
    .sort((a, b) => (a.name > b.name && 1) || -1);

  const shapesArray = createShapesMainEvaluate(
    selectedBasin
  );
  const annotationsArray = createAnnotationsCustom(
    timeseries,
    thresholds,
    selectedBasin
  );

  return (
    <div className="main-plot-custom">
      <div className="plot-outer">
        <Plot
          data={generatePlotDataFormat}
            layout={{
              autosize: true,
              title: {
                text:
                formatNameForTitle(selectedBasin) +
                  // selectedBasin.split(/(?=[A-Z])/).join(" ") +
                  ": " +
                  formatDateForTitle(
                    plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                      .forecastDateTime.date +
                      "T" +
                      plotableTS.find((ts) => (ts.name).startsWith("Merge_MAP_ECMWF_"))
                        .forecastDateTime.time
                  ),
                font: {
                  color: "black",
                  size: 20
                }
              },
              legend: { y: -0.1, orientation: "h" },
              showlegend: true,
              hovermode: "closest",
              yaxis2: {
                autoscale: true,
                title: {
                  text: "Streamflow<br> (cfs)",
                  font: {
                    size: 10,
                  },
                },
                gridcolor: "lightgrey",
                hoverformat: ".0f",
                mirror: "all",
                showgrid: true,
                showline: true,
                tickformat: ",",
                // zeroline: true,
                domain: [0, 0.42],
                rangemode: "tozero",
                tickfont: {
                  size: 11,
                },
              },
            yaxis3: {
              autoscale: true,
              title: {
                text: "Accum. Precip<br>(in)",
                font: {
                  size: 10
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              // zeroline: true,
              domain: [0.44, 0.64],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y3"),
              tickfont: {
                size: 11,
              }
            },
            yaxis4: {
              autoscale: true,
              title: {
                text: "Hrly. Precip<br>(in)",
                font: {
                  size: 10,
                },
              },
              gridcolor: "lightgrey",
              hoverformat: ".2f",
              mirror: "all",
              showgrid: true,
              showline: true,
              tickformat: ".2f",
              // zeroline: true,
              domain: [0.66, 0.88],
              rangemode: "tozero",
              range: setYRange(generatePlotDataFormat, "y4"),
              tickfont: {
                size: 11,
              },
            },
            xaxis: {
              title: {
                text: "Datetime",
                font: {
                  size: 11,
                },
              },
              gridcolor: "lightgrey",
              mirror: "all",
              showgrid: true,
              showline: true,
              // zeroline: true,
              range: [subtractDays(Date.now(), 10), addDays(Date.now(), 10)],
              type: 'date',
              tickfont: {
                size: 11,
              },
            },
            // margin: { r: 20 },
            margin: { t: 45, r:8},
            annotations: annotationsArray,
            shapes: shapesArray,
            // shapes: [{
            //   type: 'line',
            //   x0: Date.now(),
            //   y0: 0,
            //   x1: Date.now(),
            //   yref: 'paper',
            //   y2: 0.95,
            //   line: {
            //     color: 'red',
            //     width: 1,
            //     dash: 'dot'
            //   }
            // }],
          
          }}
          useResizeHandler={true}
          className="plot-inner"
          />
    </div>
    <div className="info-div">
      <MoreInfo name={selectedBasin} />
    </div>
  </div>
  );
};