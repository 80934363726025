//Plot formatting & data prep

// We have an array of timeseries data, one item in the array is a given ts for the location
export const formatPlotData = (data) => {
  let processedTimeseries = [];
  data.forEach((ts) => {
    if (ts.events) {
      // loop through the single ts and grab all the
      // x + y coords formatted in two separate arrays
      const xCoords = getXCoords(ts.events);
      const yCoords = getYCoords(ts.events);
      /*if(ts.header.locationId == "PLEA_CAT"){
        console.log("TS: ", ts.header.moduleInstanceId);
      }*/

      // create a new object with the name of the ts,
      //the basin id(aka the name of the basin),
      //the x coords and the y coords
      const newObject = {
        name: ts.header.qualifierId
          ? ts.header.moduleInstanceId + ts.header.qualifierId[0] + ts.header.qualifierId[1]
          : ts.header.moduleInstanceId,
        basin: ts.header.locationId,
        x: xCoords,
        y: yCoords,
        forecastDateTime: ts.header.forecastDate,
      };
      processedTimeseries.push(newObject);



    }
  });
  /*if(processedTimeseries[0].basin == "JAME_CAT"){
    console.log("name: ", processedTimeseries[0].basin);
    console.log("processedTS: ", processedTimeseries);
  }*/

  
  return processedTimeseries;
};

//Get coordinates
const getXCoords = (array) => {
  const xCoords = [];
  array.forEach((event) => {
    xCoords.push(event.date + " " + event.time);
  });
  return xCoords;
};

const getYCoords = (array) => {
  const yCoords = [];
  array.forEach((event) => {
    yCoords.push(parseFloat(event.value));
  });
  return yCoords;
};

//Colors
export const HRRR_color ="rgba(255, 165, 0, 0.95)";
export const GFS_color ="rgba(192, 0, 0, 0.95)";
export const NBM_color = "rgba(0, 176, 80, 0.95)";
export const ECMWF_color = "rgba(143, 87, 255, 0.95)";
export const MRMS_color = "rgba(107, 107, 120, 1)";
export const USGS_gage_color = "rgba(250, 160, 5, 1)";
export const BestAvailable_color = "rgba(255, 165, 0, 0.95)";
export const Unadj_color = "rgba(104, 118, 153, 0.5)";

//Line Style
export const generateLineStyle = (name) => {
  let lineStyle = {};
  if (name.includes("HRRR")
  ) {
    lineStyle = { color: HRRR_color, width: 2 };
  } 
  if (name.includes("GFS")
  ) {
    lineStyle = { color: GFS_color, width: 2};
  } 
  if (name.includes("NBM")
  ) {
    lineStyle = { color: NBM_color, width: 2};
  } 
  if (name.includes("ECMWF")
  ) {
    lineStyle = { color: ECMWF_color, width: 2};
  }
  if (name.includes("MRMS")
  ) {
    lineStyle = { color: MRMS_color, width: 2, dash: "solid" };
  } 
  if (name.includes("Obs")
  ) {
    lineStyle = { color: MRMS_color, width: 3, dash: "solid" };
  } 
  if (name.includes("Threshold")
  ) {
    lineStyle = { color: "black", width: 3, dash: "solid" };
  } 
  if (name.includes("Preprocess_QR")
  ) {
    lineStyle = { color: USGS_gage_color, width: 2.5, dash: "dot" };
  } 
  if (name.includes("BestAvailable")
  ) {
    lineStyle = { color: BestAvailable_color, width: 2, dash: "solid" };
  } 
  if (name.includes("Unadj")
  ) {
    lineStyle = { color: Unadj_color, width: 2, dash: "solid" };
  } 
  return lineStyle;
};

//Marker style
export const generateMarkerStyle = (name) => {
  let markerStyle = {};
  if (name.includes("HRRR")) {
    markerStyle = { color: HRRR_color };
  } 
  if (name.includes("GFS")) {
    markerStyle = { color: GFS_color };
  } 
  if (name.includes("NBM")) {
    markerStyle = { color: NBM_color };
  } 
  if (name.includes("ECMWF")) {
    markerStyle = { color: ECMWF_color};
  } 
  if (name.includes("MRMS")) {
    markerStyle = { color: MRMS_color };
  } 
  if (name.includes("Obs")) {
    markerStyle = { color: MRMS_color };
  }
  if (name.includes("BestAvailable")) {
    markerStyle = { color: BestAvailable_color };
  } 
  if (name.includes("Unadj")) {
    markerStyle = { color: Unadj_color };
  } 
  return markerStyle;
};

//Legend
export const chooseLegendGroup = (name) => {
  let legendGroup;
  if (name.includes("ECMWF")) {
    legendGroup = "ECMWF";
  } 
  if (name.includes("HRRR")) {
    legendGroup = "HRRR";
  } 
  if (name.includes("NBM")) {
    legendGroup = "NBM";
  } 
  if (name.includes("GFS")) {
    legendGroup = "GFS";
  } 
  if (name.includes("HRRR_ForecastUnadj")) {
    legendGroup = "Unadj";
  } 
  if (name.includes("HRRR_ForecastObs")) {
    legendGroup = "Obs";
  } 
  if (name.includes("MRMS")) {
    legendGroup = "MRMS";
  } 
  return legendGroup;
}

/*
Valid for both Inflow Page & Headwaters Page
Y Axises Order & Contents
y4 Hourly Precip
y3 Accumulated Precip
y2 Stream Flow
y1 Pool Elevation
*/
export const chooseYAxis = (name) => {
  //console.log("name: ",name);
  let yaxis;
  if (name.includes("_Forecast_customECMWF")) {
    yaxis = "y4";
  } 
  if (name.includes("_Forecast_customHRRR")) {
    yaxis = "y4";
  } 
  if (name.includes("_Forecast_customNBM")) {
    yaxis = "y4";
  } 
  if (name.includes("_Forecast_customGFS")) { 
    yaxis = "y4";
  } 
  if (name.includes("_Forecast_customBestAvailable")) {
    yaxis = "y4";
  } 
  if (name.includes("_HRRR_ForecastObs")) {
    yaxis = "y4";
  } 
  if (name.includes("QPE")) {
    yaxis = "y4";
  } 
  if (name.includes("MRMS")) {
    yaxis = "y4";
  } 
  if (name.includes("cumulative")) { //Changed for Now for Editing Purposes
    yaxis = "y3";
  } 
  /*
//Changed for Now for Formatting Purposes
  if (name.includes("ECMWF_grids_to_FMAPcumulative")) { 
    yaxis = "y3";

  }   
  if (name.includes("NBM_grids_to_FMAPcumulative")) {
    yaxis = "y2";

  }   
  if (name.includes("GFS_grids_to_FMAPcumulative")) { 
    yaxis = "y1";
  } 
  */
  
  if (name.includes("ADJUST")) {
    yaxis = "y2";
  } 
  if (name.includes("Threshold")) {
    yaxis = "y2";
  } 
  if (name.includes("Inflow")) {
    yaxis = "y2";
  } 

  if (name.includes("Pool")) {
    yaxis = "y1";
  } 
  return yaxis;
};

export const chooseFill = (name) => {
  let fill;
  //Review
  if (name === "Merge_MAP_HRRR_Tuck_Forecast_customHRRR" ||
      name === "Merge_MAP_HRRR_Nant_Forecast_customHRRR" 
  ) {
    fill = "tonexty";
  } else {
    fill = null;
  }
  return fill;
};

export const chooseFillColor = (name) => {
  let fillColor;
  //Review
  if (
    name === "Merge_MAP_HRRR_Tuck_Fore" ||
    name === "Merge_MAP_HRRR_N" 
  ) {
    fillColor = "rgba(0, 102, 255, 0.3)";
  } else {
    fillColor = null;
  }
  return fillColor;
};

export const chooseType = (name) => {
  let type;
  if (name.includes("Merge_MAP_")) {
    type = "bar";
  } 
  if (name.includes("cumulative")) {
    type = "line";
  } 
  if (name.includes("MRMS")) {
    type = "bar";
  } 
  if (name.includes("ADJUST")) {
    type = "line";
  } 
  if (name.includes("Threshold")) {
    type = "line";
  } 

  return type;
};

export const chooseWidth = (name) => {
  let width;
  if (name.includes("Merge_MAP_")) {
    width = 3600000;
  } 
  if (name.includes("MRMS")) {
    width = 3600000;
  } 
  return width;
};

export const chooseOffset = (name) => {
  // we need this since the bars naturally plot to the right, but we want them to the left
  let offset;
  if (name.includes("Merge_MAP_")) {
    offset = -3600000;
  } 
  if (name.includes("MRMS")) {
    offset = -3600000;
  } 
  return offset;
};

//Time processing
export const findHindcastStart = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);
  return tsStart.setTime(tsStart.getTime());
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export const subtractDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export const findForecastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  const tsEnd = new Date(tsOfInterest?.x[0]);
  const dateOffset = 24 * 60 * 60 * 1000 * 7; //0.7 days
  return tsEnd.setTime(tsEnd.getTime() + dateOffset);
};

export const findPreMediumForecastMiddle = (array, tsName) => {
  const tsOfInterest = array.find((ts) => ts.name === tsName);
  const tsStart = new Date(tsOfInterest?.x[0]);
  const dateOffset = 24 * 60 * 60 * 1000 * 0.7; //0.7 days
  return tsStart.setTime(tsStart.getTime() - dateOffset);
};

export const findPreMediumForecastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  return new Date(tsOfInterest?.x[0]);
};

export const findHindcastEnd = (array, tsName) => {
  const tsOfInterest = array.find((ts) => (ts.name).startsWith(tsName));
  return new Date(tsOfInterest?.x[tsOfInterest.x.length - 1]);
};

export const findPostShortTermStart = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const rectangleStart = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 1.25; 
  return rectangleStart?.getTime() + dateOffset;
};

export const findPostShortTermEnd = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 11;
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  return rectangleEnd?.getTime() + dateOffset;
};

export const findPostShortTermMiddle = (array, tsName) => {
  const tsOfInterest = array.find(
    (ts) => ts.header.moduleInstanceId === tsName
  );
  const dateOffset = 24 * 60 * 60 * 1000 * 5.5; //5.5 days
  const rectangleEnd = new Date(
    tsOfInterest.header.forecastDate.date +
      "T" +
      tsOfInterest.header.forecastDate.time +
      ".000Z"
  );
  return rectangleEnd?.getTime() + dateOffset;
};

export const formatDateForTitle = (originalDate) => {
  const dateObj = new Date(originalDate);
  return dateObj.toLocaleString({
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

//Thresholds
export const thresholdVisible = (array, basin) => {
  if (array.filter((item) => item.name === basin).length > 0) {
    return true;
  } else {
    return false;
  }
};
export const findThresholdValue = (array) => {
  const tsOfInterest = array.find(
    (ts) =>
      ts.header.moduleInstanceId === "ThresholdsCustom" &&
      ts.header.qualifierId[0] === "ValueThreshold"
  );
  if (tsOfInterest) {
    return parseInt(tsOfInterest?.events[0].value);
  } else {
    return 0;
  }
};
//Cumulative precip calcs
const findCenterOfPastPrecip = (forecastDateTime) => {
  const forecastStart = Date.parse(
    forecastDateTime.date + " " + forecastDateTime.time
  );
  // add 18 hours (64800000 ms in 18 hours)
  return new Date(forecastStart).getTime() + 64800000;
};

export const createPastCumulativePrecipValuesArray = (ts) => {
  // calculate the total precip amount in the 36 hours historic period
  const sum = !ts.events
    ? "No data"
    : ts.events
        .map((timestep) => parseFloat(timestep.value))
        .reduce((prev, next) => prev + next);

  return {
    wfpType: "accumulation_anno",
    aggregation_name: "SRPB",
    visible: true,
    xref: "x",
    yref: "paper",
    y: 0.98,
    xanchor: "center",
    // add function to find the center of the historic past 36 hour period
    x: findCenterOfPastPrecip(ts.header.startDate),
    yanchor: "middle",
    text: sum.toFixed(2),
    showarrow: false,
    font: {
      color: "rgb(80, 80, 80)",
      size: 11,
    },
  };
};

export const calcPastCumulativePrecip = (ts) => {
  // calculate the total precip amount in the 36 hours historic data period
  const sum = ts.y
    .map((value) => parseFloat(value))
    .reduce((prev, next) => prev + next);
  return sum.toFixed(2);
};

export const setYRange = (data, yaxis) => {
  // default y max is set to 1, but if the data have larger values than 1, this will scale the yaxis range up to match the data
  const thisYaxisTs = data.filter((ts) => {
    return ts.yaxis === yaxis;
  });

  let max = 1;

  thisYaxisTs.forEach((ts) => {
    ts.y.forEach((value) => {
      if (value > max) {
        max = value * 1.2;
      }
    });
  });

  return [0, max];
};


//Swap Id for Basin Name
export const formatNameForTitle = (originalName) => {
  let newName;
  switch (originalName) {
    case "WOLF_TUC":
      newName = "Wolf Creek";
      break;
    case "TANA_TUC":
      newName = "Tanasee";
      break;
    case "BEAR_TUC":
      newName = "Bear Creek";
      break;
    case "CEDA_TUC":
      newName = "Cedar Cliff";
      break;
    case "GLEN_TUC":
      newName = "Glenville";
      break;
    case "THOR_TUC":
      newName = "Thorpe";
      break;

    case "CEDA_CAT":
      newName = "Cedar Creek";
      break;
    case "FISH_CAT":
      newName = "Fishing Creek";
      break;
    case "GREA_CAT":
      newName = "Great Fall";
      break;
    case "HICK_CAT":
      newName = "Hickory";
      break;
    case "JAME_CAT":
      newName = "James";
      break;
    case "NORM_CAT":
      newName = "Norman";
      break;
    case "RHOD_CAT":
      newName = "Rhodiss ";
      break;
    case "WATR_CAT":
      newName = "Wateree";
      break;
    case "WYLI_CAT":
      newName = "Wylie";
      break;
    case "LOOK_CAT":
      newName = "Lookout Shoals";
      break;
    case "MOUN_CAT":
      newName = "Mountain Is.";
      break;
    
    case "RAIN_NAN":
      newName = "Rainbow Springs";
      break;
    case "LAKE_NAN":
      newName = "Nantahala Lake";
      break;
    case "WHIT_NAN":
      newName = "Whiteoak Creek";
      break;
    case "QUEE_NAN":
      newName = "Queens Creek";
      break;
    case "DICK_NAN":
      newName = "Dicks Creek";
      break;
    case "HYDR_NAN":
      newName = "Nantahala Station Bypass";
      break;

    case "BLEW_YAD":
      newName = "Blewett";
      break;
    case "FALL_YAD":
      newName = "Falls";
      break;
    case "HIGH_YAD":
      newName = "High Rock";
      break;
    case "TILL_YAD":
      newName = "Tillery";
      break;
    case "NARR_YAD":
      newName = "Narrows";
      break;
    case "TUCK_YAD":
      newName = "Tuckertown";
      break;
    case "KERR_YAD":
      newName = "Kerr Scott";
      break;

    case "GAGE_TUC":
      newName = "Below Lakes (Local)";
      break;
    case "CANE_TUC":
      newName = "Caney Fork";
      break;
    case "SAVA_TUC":
      newName = "Savannah Creek";
      break;
    case "CULL_TUC":
      newName = "Cullowhee Creek";
      break;
    case "BELW_TUC":
      newName = "Below Cullowhee";
      break;
    case "DILL_TUC":
      newName = "Dillsboro";
      break;

    case "PLEA_CAT":
      newName = "Pleasant Gardens";
      break;
    case "JOHN_CAT":
      newName = "Johns River";
      break;
    case "LOWL_CAT":
      newName = "Lower Little R";
      break;
    case "ROCK_CAT":
      newName = "Rocky Creek";
      break;
    case "SFOR_CAT":
      newName = "South Fork";
      break;
    case "SUGA_CAT":
      newName = "Sugar Creek";
      break;
    case "FCRK_CAT":
      newName = "Fishing Creek";
      break;
    case "LINV_CAT":
      newName = "Linville River";
      break;
    case "MCDO_CAT":
      newName = "McDowell Cr.";
      break;
    case "MIDL_CAT":
      newName = "Middle Little R";
      break;
    case "UPPL_CAT":
      newName = "Upper Little R";
      break;
    case "DUTC_CAT":
      newName = "Dutchmans Cr.";
      break;
    case "WARR_CAT":
      newName = "Warrior Fork";
      break;
    case "MORG_CAT":
      newName = "Abv Rhodiss (Local)";
      break;
    case "BELW_CAT":
      newName = "Bowater (excluding Wylie)";
      break;

    case "ABBO_YAD":
      newName = "Abbotts Creek";
      break;
    case "ARAR_YAD":
      newName = "Ararat River";
      break;
    case "REDD_YAD":
      newName = "Reddies River";
      break;
    case "IRSH_YAD":
      newName = "Rocky at Irish";
      break;
    case "MOCK_YAD":
      newName = "S. Yadkin Mocksville";
      break;
    case "PATT_NAN":
      newName = "Patterson";
      break;
    case "ELKC_YAD":
      newName = "Elk Creek";
      break;
    case "HUNT_YAD":
      newName = "Hunting Creek";
      break;
    case "LITT_YAD":
      newName = "Little River";
      break;
    case "LYAD_YAD":
      newName = "Little Yadkin";
      break;
    case "SECO_YAD":
      newName = "Second Creek";
      break;
    case "MITC_YAD":
      newName = "Mitchell River";
      break;
    case "ROAR_YAD":
      newName = "Roaring River";
      break;
    case "UWHA_YAD":
      newName = "Uwharrie River";
      break;
    case "NORW_YAD":
      newName = "Rocky at Norwood";
      break;
    case "ELKN_YAD":
      newName = "Elkin";
      break;
    case "ENON_YAD":
      newName = "Enon";
      break;
    case "WILK_YAD":
      newName = "Wilkesboro";
      break;
    case "YADC_YAD":
      newName = "Yadkin College";
      break;
    case "UPPR_YAD":
      newName = "Upper Yadkin";
      break;
    case "FISH_YAD":
      newName = "Fisher River";
      break;
    case "CONF_YAD":
      newName = "Confluence";
      break;
    case "PATT_YAD":
      newName = "Patterson";
      break;
    case "SYAD_YAD":
      newName = "S. Yadkin";
      break;
    case "ROCK_YAD":
      newName = "Rocky River";
      break;
    case "SILV_NAN":
      newName = "Silvermine Creek";
      break;
    case "OUTD_NAN":
      newName = "Outdoor Center";
      break;
    case "HEWI_NAN":
      newName = "Hewitt";
      break;

    default:
      newName = originalName;
  }
  return newName;
};


//Plot Trace
export const choosePlotTraceName = (name) => {
  let trace = name.replace("Merge_MAP_GFS_Tuck_Forecast_customGFS", " GFS");
  trace = trace.replace("Merge_MAP_GFS_Nant_Forecast_customGFS", " GFS");
  trace = trace.replace("Merge_MAP_GFS_Catawba_Forecast_customGFS", " GFS");
  trace = trace.replace("Merge_MAP_GFS_Yadkin_Forecast_customGFS", " GFS");

  trace = trace.replace("Merge_MAP_HRRR_Tuck_Forecast_customHRRR", " HRRR");
  trace = trace.replace("Merge_MAP_HRRR_Nant_Forecast_customHRRR", " HRRR");
  trace = trace.replace("Merge_MAP_HRRR_Catawba_Forecast_customHRRR", " HRRR");
  trace = trace.replace("Merge_MAP_HRRR_Yadkin_Forecast_customHRRR", " HRRR");

  trace = trace.replace("Merge_MAP_NBM_Tuck_Forecast_customNBM", " NBM");
  trace = trace.replace("Merge_MAP_NBM_Nant_Forecast_customNBM", " NBM");
  trace = trace.replace("Merge_MAP_NBM_Catawba_Forecast_customNBM", " NBM");
  trace = trace.replace("Merge_MAP_NBM_Yadkin_Forecast_customNBM", " NBM");

  trace = trace.replace("Merge_MAP_ECMWF_Tuck_Forecast_customECMWF", " ECMWF");
  trace = trace.replace("Merge_MAP_ECMWF_Nant_Forecast_customECMWF", " ECMWF");
  trace = trace.replace("Merge_MAP_ECMWF_Catawba_Forecast_customECMWF", " ECMWF");
  trace = trace.replace("Merge_MAP_ECMWF_Yadkin_Forecast_customECMWF", " ECMWF");

  trace = trace.replace("Preprocess_HRRR_grids_to_FMAPcumulative", " HRRR");
  trace = trace.replace("Preprocess_GFS_grids_to_FMAPcumulative", " GFS");
  trace = trace.replace("Preprocess_NBM_grids_to_FMAPcumulative", " NBM");
  trace = trace.replace("Preprocess_ECMWF_grids_to_FMAPcumulative", " ECMWF");

  trace = trace.replace("sCustomValueThreshold", "");
  trace = trace.replace("Preprocess_QR", "USGS Gage");
  trace = trace.replace("_ForecastAdj", "");
  trace = trace.replace("_HRRR_ForecastObs", "Adjusted");
  trace = trace.replace("HRRR_ForecastUnadj", "Simulated");

  trace = trace.replace("Preprocess_MRMS_grids_to_MAPmerged", "MRMS");
  trace = trace.replace("sCustomBooleanThreshold", "");
  trace = trace.replace("NARR", "");
  trace = trace.replace("KERR", "");
  trace = trace.replace("JAME", "");
  trace = trace.replace("RHOD", "");
  trace = trace.replace("HICK", "");
  trace = trace.replace("LOOK", "");
  trace = trace.replace("NORM", "");
  trace = trace.replace("MOUN", "");
  trace = trace.replace("WYLI", "");
  trace = trace.replace("FISH", "");
  trace = trace.replace("CEDA", "");
  trace = trace.replace("WATR", "");
  trace = trace.replace("PLEA", "");
  trace = trace.replace("LINV", "");
  trace = trace.replace("MORG", "");
  trace = trace.replace("WARR", "");
  trace = trace.replace("JOHN", "");
  trace = trace.replace("LOWL", "");
  trace = trace.replace("ROCK", "");
  trace = trace.replace("SFOR", "");
  trace = trace.replace("SUGA", "");
  trace = trace.replace("FCRK", "");
  trace = trace.replace("MCDO", "");
  trace = trace.replace("MIDL", "");
  trace = trace.replace("UPPL", "");
  trace = trace.replace("DUTC", "");
  trace = trace.replace("BELW", "");
  trace = trace.replace("ELKC", "");
  trace = trace.replace("REDD", "");
  trace = trace.replace("WILK", "");
  trace = trace.replace("MITC", "");
  trace = trace.replace("FISH", "");
  trace = trace.replace("ARAR", "");
  trace = trace.replace("LYAD", "");
  trace = trace.replace("ELKN", "");
  trace = trace.replace("ENON", "");
  trace = trace.replace("ABBO", "");
  trace = trace.replace("IRSH", "");
  trace = trace.replace("NAN", "");
  trace = trace.replace("MOCK", "");
  trace = trace.replace("HUNT", "");
  trace = trace.replace("LITT", "");
  trace = trace.replace("SECO", "");
  trace = trace.replace("ROAR", "");
  trace = trace.replace("UWHA", "");
  trace = trace.replace("NORW", "");
  trace = trace.replace("YADC", "");
  trace = trace.replace("SYAD", "");
  trace = trace.replace("ROCK", "");
  trace = trace.replace("CONF", "");
  trace = trace.replace("GLEN", "");
  trace = trace.replace("THOR", "");
  trace = trace.replace("TANA", "");
  trace = trace.replace("WOLF", "");
  trace = trace.replace("BEAR", "");
  trace = trace.replace("CEDA", "");
  trace = trace.replace("GAGE", "");
  trace = trace.replace("CANE", "");
  trace = trace.replace("SAVA", "");
  trace = trace.replace("CULL", "");
  trace = trace.replace("BELW", "");
  trace = trace.replace("DILL", "");
  trace = trace.replace("LAKE", "");
  trace = trace.replace("DICK", "");
  trace = trace.replace("WHIT", "");
  trace = trace.replace("HYDR", "");
  trace = trace.replace("QUEE", "");
  trace = trace.replace("OUTD", "");
  trace = trace.replace("RAIN", "");
  trace = trace.replace("SILV", "");
  trace = trace.replace("HEWI", "");
  trace = trace.replace("PATT", "");
  trace = trace.replace("UPPR", "");
  trace = trace.replace("BLEW", "");
  trace = trace.replace("HIGH", "");
  trace = trace.replace("TILL", "");
  trace = trace.replace("TUCK", "");
  trace = trace.replace("FALL", "");
  trace = trace.replace("GREA", "");

  trace = trace.replace("YAD", "");
  trace = trace.replace("CAT", "");
  trace = trace.replace("TUC", "");
  trace = trace.replace("NAN", "");

  trace = trace.replace("ADJUSTQ___HRRR", " HRRR");
  trace = trace.replace("ADJUSTQ___NBM", " NBM");
  trace = trace.replace("ADJUSTQ___GFS", " GFS");
  trace = trace.replace("ADJUSTQ___ECMWF", " ECMWF");
  trace = trace.replace("ADJUSTQ___Simulated", "Flow Simulated");
  trace = trace.replace("ADJUSTQ__Adjusted", "Flow Adjusted");


  trace = trace.replace("RW_Catawba_BridgewaterTotal Inflow","")

  trace = trace.replace("RW_Catawba_BridgewaterPool Elevation","")

  trace = trace.replace("ECMWFECMWF", "ECMWF");
  trace = trace.replace("undefined", "");

  return trace;
};