import { Grid, Paper, styled } from "@mui/material";
import { ThumbnailPlotEvaluate } from "./ThumbnailPlotEvaluate.jsx";


const ThumbnailItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "40vh",
}));

export const ThumbnailContainerEvaluate = ({ allTs, watershedId, basinClick, goBackDays }) => {
  //filter for selected watershed
  const allTsFilter = allTs.filter((ts) => ts.name.includes(watershedId))
  console.log(allTs);
  console.log(watershedId);
  console.log(allTsFilter);
  return allTsFilter.map((basin) => {
    return (
      <Grid item xs={4} key={basin.name}>
        <ThumbnailItem>
          <ThumbnailPlotEvaluate basin={basin} basinClick={basinClick} goBackDays={goBackDays} />
        </ThumbnailItem>
      </Grid>
    );
  });
};
